import moment from "jalali-moment";
import starkString from "starkstring";
export default (date) => {
  date = starkString(moment(date).format('YYYY/MM/DD HH:mm:ss +0000')).englishNumber().toString()
  let dateTime = new Date((typeof date === "string" ? new Date(date) :date).toLocaleString("en-US", {timeZone: "Asia/Tehran"}))
  return moment(dateTime).locale('fa').format('jYYYY/jMM/jDD HH:mm') ;
};

export const convertDateOnly =(date)=> {
  date = starkString(moment(date).format('YYYY/MM/DD HH:mm:ss +0000')).englishNumber().toString()
  let dateTime = new Date((typeof date === "string" ? new Date(date) :date).toLocaleString("en-US", {timeZone: "Asia/Tehran"}))
  return moment(dateTime).locale('fa').format('jYYYY/jMM/jDD') ;
};